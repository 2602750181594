// src/modules/Dashboard/components/UserMakes/UserMakes.tsx

import React from "react";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { app } from "../../../../config/firebaseConfig";
import {
  UserMakesContainer,
  UserMakesHeader,
  UserMakesStyledTable,
  UserMakesTableHeader,
  UserMakesButtonIcon,
  AllUserMakesButtonContainer,
  AllUserMakesButton,
} from "./UserMakes.styled";
import { UserMakesTable } from "./UserMakesTable";
import googleDriveIcon from "../../../../assets/images/icon-google-drive.png";
import { useUserMakes } from "./hooks/useUserMakes";
import { useUserActions } from "./hooks/useUserActions";

// Initialize Firebase
const auth = getAuth(app);
const db = getFirestore(app);

export interface UserMake {
  id: string;
  makeDateTimeUNIX: string;
  makeTitle: string;
  makeJobPostURL: string;
  makeFileIDMakeFolder: string;
  makeFileIDMakeResumePDF: string;
  makeStatus: string;
}

export interface UserMakesData {
  userMakes: UserMake[];
  userFolderMakes: string | null;
}

export const UserMakes: React.FC = () => {
  // Utilize the custom hook to manage user makes and related state
  const {
    userMakes,
    userFolderMakes,
    email,
    isDeleting,
    isMakingCoverLetter,
    setUserMakes,
    setIsDeleting,
    setIsMakingCoverLetter,
  } = useUserMakes();

  // Utilize the custom hook to handle user actions
  const { handleDelete, handleMakeCoverLetter } = useUserActions({
    email,
    setUserMakes,
    setIsDeleting,
    setIsMakingCoverLetter,
  });

  // Toggle the visibility of the user makes table
  const toggleTable = () => {
    document.getElementById("userMakesTable")?.classList.toggle("hidden");
  };

  // Open the user's makes folder in Google Drive
  const openUserMakesFolder = () => {
    if (!userFolderMakes) {
      alert("No folder found for user makes.");
      return;
    }
    window.open(
      `https://drive.google.com/drive/folders/${userFolderMakes}`,
      "_blank"
    );
  };

  return (
    <UserMakesContainer>
      <UserMakesHeader onClick={toggleTable}>User Makes</UserMakesHeader>
      <UserMakesStyledTable id="userMakesTable">
        <thead>
          <tr>
            <UserMakesTableHeader>DATE</UserMakesTableHeader>
            <UserMakesTableHeader>JOB</UserMakesTableHeader>
            <UserMakesTableHeader>GD</UserMakesTableHeader>
            <UserMakesTableHeader>PDF</UserMakesTableHeader>
            <UserMakesTableHeader>STATUS</UserMakesTableHeader>
            <UserMakesTableHeader>COVER LETTER</UserMakesTableHeader>
            <UserMakesTableHeader>DELETE</UserMakesTableHeader>
          </tr>
        </thead>
        <tbody>
          <UserMakesTable
            makes={userMakes}
            onDelete={handleDelete}
            onMakeCoverLetter={handleMakeCoverLetter}
            isDeleting={isDeleting}
            isMakingCoverLetter={isMakingCoverLetter}
          />
        </tbody>
      </UserMakesStyledTable>
      <AllUserMakesButtonContainer>
        <AllUserMakesButton onClick={openUserMakesFolder}>
          <UserMakesButtonIcon src={googleDriveIcon} alt="Google Drive Icon" />
          All User Makes
        </AllUserMakesButton>
      </AllUserMakesButtonContainer>
    </UserMakesContainer>
  );
};
