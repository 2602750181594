// src/modules/Dashboard/components/BaseResumes/hooks/useWebhooks.ts

import { WEBHOOKS } from "../../../../../config";

export const useWebhooks = () => {
  const sendUpdateDefaultResumeWebhook = async (
    docId: string,
    userEmail: string
  ): Promise<boolean> => {
    const webhookUrl = WEBHOOKS.UPDATE_DEFAULT_RESUME;

    if (!webhookUrl) {
      console.error(
        "Webhook URL is not defined. Please set REACT_APP_WEBHOOK_UPDATE_DEFAULT_RESUME in your .env file."
      );
      return false;
    }

    try {
      const response = await fetch(webhookUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ docId, email: userEmail }),
      });

      if (response.ok) {
        console.log("Set Default Resume webhook sent successfully.");
        return true;
      }
      console.error(
        "Failed to send Set Default Resume webhook. Status:",
        response.status
      );
      return false;
    } catch (error) {
      console.error("Error sending Set Default Resume webhook:", error);
      return false;
    }
  };

  const sendDeleteWebhook = async (
    docId: string,
    userEmail: string
  ): Promise<boolean> => {
    const webhookUrl = WEBHOOKS.MAKE_DELETE;

    if (!webhookUrl) {
      console.error(
        "Webhook URL is not defined. Please set REACT_APP_WEBHOOK_MAKE_DELETE in your .env file."
      );
      return false;
    }

    try {
      const response = await fetch(webhookUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ docId, email: userEmail }),
      });

      return response.ok;
    } catch (error) {
      console.error("Error sending delete webhook:", error);
      return false;
    }
  };

  return { sendUpdateDefaultResumeWebhook, sendDeleteWebhook };
};
