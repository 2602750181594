// src/modules/Dashboard/components/BaseResumes/BaseResumesTable.tsx

import React from "react";
import {
  BaseResumesTableRow,
  BaseResumesTableData,
  BaseResumesDefaultCheck,
  BaseResumesStyledIcon,
  SetDefaultButton,
  DeleteButton,
} from "./BaseResumes.styled";
import googleDocsIcon from "../../../../assets/images/icon-google-docs.png";

interface UserBaseResume {
  id: string;
  baseResumeName: string;
  baseResumeFileIDGDoc: string;
  makeDateTimeUNIX: string;
  makeStatus: string;
  isDefault: boolean;
}

interface BaseResumesTableProps {
  makes: UserBaseResume[];
  onDelete: (id: string) => void;
  onSetDefault: (id: string) => void;
  isDeleting: { [key: string]: boolean };
  isSettingDefault: { [key: string]: boolean };
}

export const BaseResumesTable: React.FC<BaseResumesTableProps> = ({
  makes,
  onDelete,
  onSetDefault,
  isDeleting,
  isSettingDefault,
}) => {
  console.log("Rendering ResumeTable with resumes:", makes);

  if (makes.length === 0) {
    console.log("No base resumes found.");
    return (
      <BaseResumesTableRow>
        <BaseResumesTableData colSpan={7}>
          No base resumes found.
        </BaseResumesTableData>
      </BaseResumesTableRow>
    );
  }

  return (
    <>
      {makes.map((resume) => {
        console.log("Rendering resume row for:", resume.id);
        const resumeDate = new Date(parseInt(resume.makeDateTimeUNIX, 10));
        const formattedDate = resumeDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });

        return (
          <BaseResumesTableRow key={resume.id}>
            <BaseResumesTableData>{formattedDate}</BaseResumesTableData>
            <BaseResumesTableData>{resume.baseResumeName}</BaseResumesTableData>
            <BaseResumesTableData>
              <a
                href={`https://docs.google.com/document/d/${resume.baseResumeFileIDGDoc}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <BaseResumesStyledIcon
                  src={googleDocsIcon}
                  alt="Google Docs Icon"
                />
              </a>
            </BaseResumesTableData>
            <BaseResumesTableData>
              {resume.isDefault && (
                <BaseResumesDefaultCheck>•</BaseResumesDefaultCheck>
              )}
            </BaseResumesTableData>
            <BaseResumesTableData>
              <SetDefaultButton
                onClick={() => onSetDefault(resume.id)}
                disabled={isSettingDefault[resume.id]}
              >
                {isSettingDefault[resume.id] ? "Setting..." : "Default"}
              </SetDefaultButton>
            </BaseResumesTableData>
            <BaseResumesTableData>{resume.makeStatus}</BaseResumesTableData>
            <BaseResumesTableData>
              <DeleteButton
                onClick={() => onDelete(resume.id)}
                disabled={isDeleting[resume.id]}
              >
                {isDeleting[resume.id] ? "Deleting..." : "Delete"}
              </DeleteButton>
            </BaseResumesTableData>
          </BaseResumesTableRow>
        );
      })}
    </>
  );
};
