// src/modules/Dashboard/components/UserMakes/UserMakesTable.tsx

import React from "react";
import {
  UserMakesTableHeader,
  UserMakesDateHeaderRow,
  UserMakesTableData,
  UserMakesTableRow,
  UserMakesButtonIcon,
  DeleteMakeButton,
  MakeCoverLetterButton,
} from "./UserMakes.styled";
import googleDriveIcon from "../../../../assets/images/icon-google-drive.png";
import pdfIcon from "../../../../assets/images/icon-pdf.png";

export interface UserMake {
  id: string;
  makeDateTimeUNIX: string;
  makeTitle: string;
  makeJobPostURL: string;
  makeFileIDMakeFolder: string;
  makeFileIDMakeResumePDF: string;
  makeStatus: string;
}

interface UserMakesTableProps {
  makes: UserMake[];
  onDelete: (docId: string) => void;
  onMakeCoverLetter: (docId: string) => void;
  isDeleting: { [key: string]: boolean };
  isMakingCoverLetter: { [key: string]: boolean };
}

export const UserMakesTable: React.FC<UserMakesTableProps> = ({
  makes,
  onDelete,
  onMakeCoverLetter,
  isDeleting,
  isMakingCoverLetter,
}) => {
  if (makes.length === 0) {
    return (
      <UserMakesTableRow>
        <UserMakesTableData colSpan={7}>No makes found.</UserMakesTableData>
      </UserMakesTableRow>
    );
  }

  let previousDate: string | null = null;

  return (
    <>
      {makes.map((make) => {
        const currentDate = new Date(Number(make.makeDateTimeUNIX));
        const formattedDate = currentDate.toLocaleDateString("en-US", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        const dayKey = currentDate.toDateString();

        const showDateRow = !previousDate || previousDate !== dayKey;
        previousDate = dayKey;

        return (
          <React.Fragment key={`fragment-${make.id}`}>
            {showDateRow && (
              <UserMakesDateHeaderRow>
                <UserMakesTableData colSpan={7}>
                  {formattedDate}
                </UserMakesTableData>
              </UserMakesDateHeaderRow>
            )}
            <UserMakesTableRow>
              <UserMakesTableData>
                {currentDate.toLocaleDateString()}
              </UserMakesTableData>
              <UserMakesTableData>
                <a
                  href={make.makeJobPostURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {make.makeTitle}
                </a>
              </UserMakesTableData>
              <UserMakesTableData>
                <a
                  href={`https://drive.google.com/drive/folders/${make.makeFileIDMakeFolder}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <UserMakesButtonIcon
                    src={googleDriveIcon}
                    alt="Google Drive Icon"
                  />
                </a>
              </UserMakesTableData>
              <UserMakesTableData>
                <a
                  href={`https://drive.google.com/file/d/${make.makeFileIDMakeResumePDF}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <UserMakesButtonIcon src={pdfIcon} alt="PDF Icon" />
                </a>
              </UserMakesTableData>
              <UserMakesTableData>{make.makeStatus}</UserMakesTableData>
              <UserMakesTableData>
                <MakeCoverLetterButton
                  onClick={() => onMakeCoverLetter(make.id)}
                >
                  {isMakingCoverLetter[make.id] ? "Making..." : "Make"}
                </MakeCoverLetterButton>
              </UserMakesTableData>
              <UserMakesTableData>
                <DeleteMakeButton onClick={() => onDelete(make.id)}>
                  {isDeleting[make.id] ? "Deleting..." : "Delete"}
                </DeleteMakeButton>
              </UserMakesTableData>
            </UserMakesTableRow>
          </React.Fragment>
        );
      })}
    </>
  );
};
