// src/modules/Dashboard/components/SetupResume/SetupResume.styled.tsx

// Define Styled Components

import React from "react";
import styled from "styled-components";
import * as Primitive from "../../../../components/primitives";

// Modal backdrop
export const SeupResumeBackdrop = styled(Primitive.ModalBackdrop)`
  /* Styles */
`;

// Modal container
export const SetupResumeContainer = styled(Primitive.ModalContainer)`
  /* Styles */
`;

// Header
export const SetupResumeHeader = styled.h2`
  margin-bottom: 20px;
  text-align: center;
`;

// Form
export const SetupResumeForm = styled(Primitive.Form)`
  /* Styles */
`;

// Label
export const SetupResumeLabel = styled(Primitive.Label)`
  margin-bottom: 5px;
  font-weight: bold;
`;

// Input fields
export const SetupResumeInput = styled(Primitive.Input)`
  /* Styles */
`;

export const SetupResumeButton = styled(Primitive.SquareButton)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

// Status message
export const SetupResumeStatusMessage = styled(
  Primitive.LoadingMessageContainer
)`
  margin-top: 30px;
  height: 20px;
`;
