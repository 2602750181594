// src/App.tsx

import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { app } from "./config/firebaseConfig";
import { initializeAuth, handleRedirectResult } from "./services/auth/auth";
import { Dashboard } from "./modules/Dashboard/Dashboard";
import SignIn from "./modules/SignIn/SignIn";
import { AppHeader, AppSubheader, MainContainer } from "./App.styled";
import "./App.css";

const auth = getAuth(app);

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [testEmail, setTestEmail] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  // Function to get the test email from the URL
  const getTestEmailFromURL = (): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("email");
  };

  useEffect(() => {
    const initialize = async () => {
      await initializeAuth();

      // Handle redirect result after sign-in
      await handleRedirectResult();

      const emailFromURL = getTestEmailFromURL();
      setTestEmail(emailFromURL);

      if (!emailFromURL) {
        // Listen for authentication state changes
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
          if (authUser) {
            setUser(authUser);
            console.log(`User is logged in as: ${authUser.email}`);
          } else {
            setUser(null);
            console.log("No user is logged in.");
          }
          setLoading(false);
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
      } else {
        // If a test email is provided via URL, treat it as authenticated
        setLoading(false);
      }
    };

    initialize();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  // Determine if the user is authenticated
  const isAuthenticated =
    user !== null || (testEmail !== null && testEmail !== undefined);

  return (
    <div>
      <header>
        <a href="https://autocv.s-kode.com">
          <AppHeader>RunoJobs | Dashboard</AppHeader>
        </a>
        <AppSubheader className="header-subtitle">
          PRIVATE BETA 2.1
        </AppSubheader>
      </header>
      <MainContainer>
        {isAuthenticated ? (
          <Dashboard user={user} testEmail={testEmail} />
        ) : (
          <SignIn />
        )}
      </MainContainer>
      <div style={{ height: "30px" }}></div>
      {/* Root Container for Content Script? */}
      <div id="ram-root-profile" style={{ display: "none" }}></div>
    </div>
  );
};

export default App;
