// src/App.styled.tsx

import React from "react";
import styled from "styled-components";

export const AppHeader = styled.h1`
  margin-top: 8px;
  background-color: #fff;
  color: #1453b8;
  padding: 10px 20px;
  letter-spacing: 1px;
  border-radius: 10px;
  width: 95%;
  max-width: 760px;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
`;

export const AppSubheader = styled.h5`
  margin-top: -20px;
  padding: 4px 8px;
  letter-spacing: 1px;
  border-radius: 6px;
`;

export const MainContainer = styled.div`
  display: block;
  width: 100%;
  max-width: 800px;
  border: 1px solid #ffffff80;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
`;
