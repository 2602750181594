// src/modules/Dashboard/components/BaseResumes/BaseResumes.styled.tsx

import React from "react";
import styled from "styled-components";
import * as Primitive from "../../../../components/primitives";

// Styled Components

export const BaseResumesContainer = styled(Primitive.ModuleContainer)`
  /* Styles */
`;

export const BaseResumesHeader = styled(Primitive.ModuleHeader)`
  /* Styles */
`;

export const BaseResumesStyledTable = styled(Primitive.Table)`
  /* Styles */
`;

export const BaseResumesTableHeader = styled(Primitive.TableHeader)`
  /* Styles */
`;

export const BaseResumesTableRow = styled(Primitive.TableRow)`
  /* Styles */
`;

export const BaseResumesTableData = styled(Primitive.TableData)`
  /* Styles */
`;

export const SetDefaultButton = styled(Primitive.TableButton)`
  /* Styles */
`;

export const DeleteButton = styled(Primitive.TableButton)`
  background: #ff4d4f;

  &:hover {
    background: #ff7875;
  }
`;

export const SetupResumeButtonContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const SetupResumeButton = styled.button`
  display: flex;
  align-items: space-evenly;
  vertical-align: middle;
  margin-top: 20px;
  padding: 10px 20px;
  background: #ffffff;
  color: #000000;
  border: solid 1px #ffffff;
  border-radius: 6px;
  box-shadow: 10px 0 40px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  max-width: 100%;
  transition: background 0.3s ease;

  &:hover {
    background: #f2f2f2;
  }
`;

export const SetupResumeButtonSpan = styled.span`
  vertical-align: middle;
  margin: 6px;
`;

export const BaseResumesStyledIcon = styled(Primitive.TableIconButton)`
  /* styles */
`;

export const BaseResumesDefaultCheck = styled.span`
  font-size: 18px;
`;

export const BaseResumesLoadingMessage = styled(
  Primitive.LoadingMessageContainer
)`
  /* styles */
`;
