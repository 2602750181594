// src/modules/Dashboard/components/SetupResume/SetupResume.tsx

import React from "react";
import { useAuth } from "./hooks/useAuth";
import { useResumeForm } from "./hooks/useResumeForm";
import { submitResumeSetup } from "./services/resumeService";
import {
  SetupResumeContainer,
  SeupResumeBackdrop,
  SetupResumeHeader,
  SetupResumeForm,
  SetupResumeInput,
  SetupResumeLabel,
  SetupResumeButton,
  SetupResumeStatusMessage,
} from "./SetupResume.styled";

interface SetupResumeProps {
  onClose: () => void;
}

export const SetupResume: React.FC<SetupResumeProps> = ({ onClose }) => {
  const { user } = useAuth();
  const { formData, statusMessage, setStatusMessage, handleInputChange } =
    useResumeForm();

  const handleFormSubmission = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!user) {
      console.error("User is not authenticated.");
      return;
    }

    if (!user.email) {
      console.error("User email is required.");
      setStatusMessage("Error: User email is required.");
      return;
    }

    const userData = {
      gDocURL: formData.gDocURL,
      baseResumeName: formData.baseResumeName,
      userId: user.uid,
      userEmail: user.email,
      processCount: 1,
    };

    try {
      const data = await submitResumeSetup(userData);

      if (data.isPublic) {
        setStatusMessage(
          "Resume submitted. (Refresh dashboard page in 30-60s to view.)"
        );
      } else {
        setStatusMessage(
          "Error: submitted link is not publicly viewable. Update the document's sharing/visibility settings."
        );
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      setStatusMessage("Error. Resume not submitted.");
    }
  };

  return (
    <SeupResumeBackdrop onClick={onClose}>
      <SetupResumeContainer onClick={(e) => e.stopPropagation()}>
        <SetupResumeHeader>Setup Resume</SetupResumeHeader>
        <SetupResumeForm onSubmit={handleFormSubmission}>
          <SetupResumeLabel htmlFor="baseResumeName">
            Base Resume Name
          </SetupResumeLabel>
          <SetupResumeInput
            type="text"
            id="baseResumeName"
            name="baseResumeName"
            value={formData.baseResumeName}
            onChange={handleInputChange}
          />

          <SetupResumeLabel htmlFor="gDocURL">
            Paste Google Doc URL
          </SetupResumeLabel>
          <SetupResumeInput
            type="url"
            id="gDocURL"
            name="gDocURL"
            value={formData.gDocURL}
            onChange={handleInputChange}
          />

          <SetupResumeButton type="submit">Import Resume</SetupResumeButton>
        </SetupResumeForm>
        <SetupResumeStatusMessage>{statusMessage}</SetupResumeStatusMessage>
        <SetupResumeButton onClick={onClose}>Close</SetupResumeButton>
      </SetupResumeContainer>
    </SeupResumeBackdrop>
  );
};
