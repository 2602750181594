// src/modules/Dashboard/Dashboard.tsx

import React from "react";
import { UserDisplay } from "./components/UserDisplay/UserDisplay";
import { BaseResumes } from "./components/BaseResumes/BaseResumes";
import { UserMakes } from "./components/UserMakes/UserMakes";
import * as Primitive from "../../components/primitives";

interface DashboardProps {
  user: any | null;
  testEmail: string | null;
}

export const Dashboard: React.FC<DashboardProps> = ({ user, testEmail }) => {
  return (
    <div>
      <UserDisplay user={user} testEmail={testEmail} />
      <Primitive.HorizontalRule />
      <BaseResumes />
      <Primitive.HorizontalRule />
      <UserMakes />
    </div>
  );
};
