// src/components/primitives/Headers.styled.tsx

import React from "react";
import styled from "styled-components";

export const ModuleHeader = styled.h2`
  display: inline-block;
  color: #1453b8;
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  transition: transform 0.2s ease, filter 0.2s ease;
  width: 96%;
  text-align: center;
  padding: 8px 16px;
  // cursor: pointer;
`;

export const SubmoduleHeader = styled.h3`
  color: #1453b8;
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  transition: transform 0.2s ease, filter 0.2s ease;
  width: 96%;
  text-align: center;
  padding: 8px 16px;
  // cursor: pointer;
`;
