export { BaseResumes } from "./BaseResumes";
export { BaseResumesTable } from "./BaseResumesTable";
export {
  BaseResumesContainer,
  BaseResumesHeader,
  BaseResumesStyledTable,
  BaseResumesTableData,
  BaseResumesTableHeader,
  BaseResumesTableRow,
  BaseResumesDefaultCheck,
  BaseResumesLoadingMessage,
  SetupResumeButton,
  SetupResumeButtonContainer,
  SetupResumeButtonSpan,
  SetDefaultButton,
  DeleteButton,
  BaseResumesStyledIcon,
} from "./BaseResumes.styled";
