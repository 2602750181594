// src/modules/SignIn/SignIn.styled.tsx

import React from "react";
import styled from "styled-components";
import * as Primitive from "../../components/primitives";
import googleSignInButton from "../../assets/images/googleSignIn_button.png";

export const SignInContainer = styled(Primitive.ModuleContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GoogleSignInButton = styled.button`
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 220px;
  height: 40px;
  border-radius: 30px;
  background-image: url(${googleSignInButton});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
