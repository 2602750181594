// src/modules/Dashboard/components/BaseResumes/hooks/useBaseResumes.ts

import { useState, useEffect, useCallback } from "react";
import { getAuth } from "firebase/auth";
import { app } from "../../../../../config/firebaseConfig";
import { fetchUserBaseResumes } from "../services/baseResumesService";
import { useWebhooks } from "./useWebhooks";
import { getTestEmailFromURL } from "../utils/helpers";
import { UserBaseResume } from "../types";

const auth = getAuth(app);

export const useBaseResumes = () => {
  const [resumes, setResumes] = useState<UserBaseResume[]>([]);
  const [email, setEmail] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState<{ [key: string]: boolean }>({});
  const [isSettingDefault, setIsSettingDefault] = useState<{
    [key: string]: boolean;
  }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { sendDeleteWebhook, sendUpdateDefaultResumeWebhook } = useWebhooks();

  useEffect(() => {
    const emailFromURL = getTestEmailFromURL();
    setEmail(emailFromURL);

    const fetchResumes = async (userEmail: string) => {
      setLoading(true);
      const userBaseResumes = await fetchUserBaseResumes(userEmail);
      setResumes(userBaseResumes);
      setLoading(false);
    };

    if (emailFromURL) {
      fetchResumes(emailFromURL);
    } else {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user?.email) {
          fetchResumes(user.email);
        }
      });
      return () => unsubscribe();
    }
  }, []);

  const handleDeleteResume = useCallback(async (docId: string) => {
    const testEmail = getTestEmailFromURL();
    const user = auth.currentUser;
    const userEmail = testEmail || (user ? user.email : null);

    if (!userEmail) {
      console.error("No user email found for deleting the resume.");
      return;
    }

    const success = await sendDeleteWebhook(docId, userEmail);

    if (success) {
      setResumes((prevResumes) =>
        prevResumes.filter((resume) => resume.id !== docId)
      );
      console.log("Successfully deleted document with ID:", docId);
    } else {
      console.error("Failed to delete document with ID:", docId);
    }
  }, []);

  const handleSetDefaultResume = useCallback(
    async (docId: string) => {
      if (!email) {
        console.error("User email is not available.");
        return;
      }

      setIsSettingDefault((prev) => ({ ...prev, [docId]: true }));

      const success = await sendUpdateDefaultResumeWebhook(docId, email);

      if (success) {
        console.log(`Set Default Resume for docId ${docId} successfully.`);
        setResumes((prevResumes) =>
          prevResumes.map((resume) => ({
            ...resume,
            isDefault: resume.id === docId,
          }))
        );
      } else {
        console.error(`Failed to set default resume for docId ${docId}.`);
      }

      setIsSettingDefault((prev) => ({ ...prev, [docId]: false }));
    },
    [email]
  );

  return {
    resumes,
    loading,
    isDeleting,
    isSettingDefault,
    handleDeleteResume,
    handleSetDefaultResume,
  };
};
