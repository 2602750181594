// src/components/primitives/Inputs.styled.tsx

import React from "react";
import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  /* Styles */
`;

export const Input = styled.input`
  width: 90%;
  padding: 10px;
  margin-top: 5px;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
`;

export const TextArea = styled.textarea`
  width: 90%;
  padding: 10px;
  margin-top: 5px;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
`;
