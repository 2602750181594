// src/components/primitives/Buttons.styled.tsx

import React from "react";
import styled from "styled-components";

export const RoundedButton = styled.button`
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;
  padding: 0 16px;
  height: 40px;
  margin: 0 4px;
  font-size: small;
  background-color: white;
  border: 1px solid #747775;
  border-radius: 30px;
  box-sizing: border-box;
  color: #1f1f1f;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.2px;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }

  @media (max-width: 768px) {
    max-width: 300px;
    margin: 10px auto;
  }
`;

export const SquareButton = styled.button`
  padding: 10px 16px;
  background-color: "#ffffff";
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const TableButton = styled.button`
  background: #3c7add;
  padding: 6px 10px;
  color: #ffffff;
  border: solid 1px #ffffff;
  border-radius: 3.125rem;
  font-size: x-small;
  font-weight: 700;
  text-align: center;
  box-shadow: 10px 0 40px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  max-width: 100%;
  transition: background 0.3s ease;

  &:hover {
    background: #5a9bd8;
  }
`;

export const TableIconButton = styled.img`
  width: auto;
  height: 28px;
  vertical-align: middle;
  padding: 0;
  transition: transform 0.2s ease, filter 0.2s ease;

  &:hover {
    transform: scale(1.1);
    filter: brightness(0.8);
    cursor: pointer;
  }
`;
