// src/components/SignIn/SignIn.tsx

import React from "react";
import styled from "styled-components";
import { signInWithGoogle } from "../../services/auth/auth";
import { SignInContainer, GoogleSignInButton } from "./SignIn.styled";

const SignIn: React.FC = () => {
  const handleSignIn = () => {
    signInWithGoogle();
  };

  return (
    <SignInContainer>
      <GoogleSignInButton
        onClick={handleSignIn}
        aria-label="Sign in with Google"
      ></GoogleSignInButton>
    </SignInContainer>
  );
};

export default SignIn;
