// src/modules/Dashboard/components/BaseResumes/BaseResumes.tsx

import React, { useState } from "react";
import { BaseResumesTable, SetupResumeButtonContainer } from "./";
import {
  BaseResumesContainer,
  BaseResumesHeader,
  BaseResumesStyledTable,
  BaseResumesTableHeader,
  BaseResumesLoadingMessage,
  SetupResumeButton,
  SetupResumeButtonSpan,
} from "./";
import { SetupResume } from "../SetupResume/SetupResume";
import { useBaseResumes } from "./hooks/useBaseResumes";

export const BaseResumes: React.FC = () => {
  const [showSetupResumeModal, setShowSetupResumeModal] = useState(false);

  const {
    resumes,
    loading,
    isDeleting,
    isSettingDefault,
    handleDeleteResume,
    handleSetDefaultResume,
  } = useBaseResumes();

  return (
    <BaseResumesContainer>
      <BaseResumesHeader
        className="dashboard-module-header"
        onClick={() => {
          const table = document.getElementById("userBaseResumesTable");
          const button = document.getElementById("btn-setup-resume");
          table?.classList.toggle("hidden");
          button?.classList.toggle("hidden");
        }}
      >
        User Base Resumes
      </BaseResumesHeader>

      {showSetupResumeModal && (
        <SetupResume onClose={() => setShowSetupResumeModal(false)} />
      )}

      {loading ? (
        <BaseResumesLoadingMessage>
          Loading resumes...
        </BaseResumesLoadingMessage>
      ) : resumes.length > 0 ? (
        <BaseResumesStyledTable
          id="userBaseResumesTable"
          className="user-makes-table"
        >
          <thead>
            <tr>
              <BaseResumesTableHeader>DATE</BaseResumesTableHeader>
              <BaseResumesTableHeader>NAME</BaseResumesTableHeader>
              <BaseResumesTableHeader>GDOC</BaseResumesTableHeader>
              <BaseResumesTableHeader>DEFAULT?</BaseResumesTableHeader>
              <BaseResumesTableHeader>SET DEFAULT</BaseResumesTableHeader>
              <BaseResumesTableHeader>STATUS</BaseResumesTableHeader>
              <BaseResumesTableHeader>DELETE</BaseResumesTableHeader>
            </tr>
          </thead>
          <tbody>
            <BaseResumesTable
              makes={resumes}
              onDelete={handleDeleteResume}
              onSetDefault={handleSetDefaultResume}
              isDeleting={isDeleting}
              isSettingDefault={isSettingDefault}
            />
          </tbody>
        </BaseResumesStyledTable>
      ) : (
        <BaseResumesLoadingMessage>No resumes found.</BaseResumesLoadingMessage>
      )}

      <div style={{ height: "20px" }} />
      <SetupResumeButtonContainer>
        <SetupResumeButton
          onClick={() => setShowSetupResumeModal(true)}
          id="btn-setup-resume"
        >
          <SetupResumeButtonSpan>Setup Base Resume</SetupResumeButtonSpan>
        </SetupResumeButton>
      </SetupResumeButtonContainer>
      <div style={{ height: "20px" }} />
    </BaseResumesContainer>
  );
};
