// src/modules/Dashboard/components/UserDisplay/UserDisplay.tsx

import React from "react";
import { useFetchUserData, useSendUserEmail, useLogoutHandler } from "./hooks";
import { UserData } from "./types";
import {
  UserDisplayContainer,
  UserDisplaySpanLeft,
  UserDisplaySpanRight,
  UserDisplayLoadingMessage,
  UserDisplayButton,
} from "./UserDisplay.styled";

interface UserDisplayProps {
  user: any | null;
  testEmail: string | null;
}

export const UserDisplay: React.FC<UserDisplayProps> = ({
  user,
  testEmail,
}) => {
  const email = testEmail || user?.email;
  const userData = useFetchUserData(email);
  useSendUserEmail(user?.email || null);
  const handleLogout = useLogoutHandler();

  const emailToDisplay = email || "Unknown User";

  return (
    <div>
      {userData ? (
        <UserDisplayContainer>
          <UserDisplaySpanLeft>{emailToDisplay}</UserDisplaySpanLeft>
          <UserDisplaySpanRight>
            Credits Used: {userData.totalCreditsUsed}
          </UserDisplaySpanRight>
          <UserDisplayButton
            onClick={() =>
              window.open(
                "https://chromewebstore.google.com/detail/autocv/micjohkmnpfkcnfoifijfdffpcajjiod",
                "_blank"
              )
            }
            aria-label="Install Extension"
          >
            Install Extension
          </UserDisplayButton>
          <UserDisplayButton onClick={handleLogout} id="logout">
            Logout
          </UserDisplayButton>
        </UserDisplayContainer>
      ) : (
        <UserDisplayLoadingMessage>
          Loading user data...
        </UserDisplayLoadingMessage>
      )}
    </div>
  );
};
