// src/components/primitives/Tables.styled.tsx

import React from "react";
import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  max-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  overflow-x: auto;
  overflow-y: hidden;
  border: 1px solid #ffffff80;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  color: #000;
  margin: 0 auto;
  box-sizing: border-box;
  table-layout: auto;

  /* Responsive Styles */
  @media (max-width: 600px) {
    font-size: 12px;

    th,
    td {
      font-size: 12px;
      padding: 8px;
    }

    .make-cl-btn,
    .delete-btn {
      font-size: 10px;
      padding: 4px 6px;
    }

    .icon {
      height: 24px;
    }

    .user-makes-btn-all {
      font-size: 12px;
      padding: 8px 12px;
    }

    .date-header {
      font-size: 13px;
      padding: 8px;
    }
  }
`;

export const TableHeader = styled.th`
  background-color: #e0e0e0;
  color: #333;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 4px;
  margin-bottom: 4px;
  text-align: center;

  &:first-child {
    border-top-left-radius: 8px;
    padding-left: 10px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    padding-right: 20px;
  }
`;

export const TableRow = styled.tr`
  font-size: 13px;
  text-align: center;

  &:not(:last-child) td {
    border-bottom: 1px solid rgba(200, 200, 200, 0.5);
  }
`;

export const TableData = styled.td`
  padding: 10px;
  background-color: #00000005;
  text-align: center;
`;
