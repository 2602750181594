// src/modules/Dashboard/components/UserDisplay/UserDisplay.styled.tsx

// Styled Components

import React from "react";
import styled from "styled-components";
import * as Primitive from "../../../../components/primitives";

export const UserDisplayContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: -10px 10px 10px 10px;
  width: 95%;
  min-height: 60px;
  border: 0px solid #ffffff80;
  border-radius: 10px;
  box-sizing: border-box;
  flex-wrap: wrap;
  color: #1f1f1f;
`;

export const UserDisplaySpan = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 16px;
  margin: 0 4px;
  min-width: fit-content;
  min-height: 10px;
  font-size: small;
  line-height: 1.5;
  background-color: #fff;
  color: #1f1f1f;
  border: 1px solid #ffffff80;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const UserDisplaySpanLeft = styled(UserDisplaySpan)`
  align-items: flex-start;
  text-align: left;
`;

export const UserDisplaySpanRight = styled(UserDisplaySpan)`
  align-items: flex-end;
  text-align: right;
`;

export const UserDisplayButton = styled(Primitive.RoundedButton)``;

export const UserDisplayLoadingMessage = styled(
  Primitive.LoadingMessageContainer
)``;
