// src/modules/Dashboard/components/UserMakes/hooks/useUserActions.ts

import { useCallback } from "react";
import {
  sendDeleteWebhook,
  sendMakeCoverLetterWebhook,
} from "../services/userMakesService";
import { UserMake } from "../UserMakes";

interface UseUserActionsProps {
  email: string | null;
  setUserMakes: React.Dispatch<React.SetStateAction<UserMake[]>>;
  setIsDeleting: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  setIsMakingCoverLetter: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
}

export const useUserActions = ({
  email,
  setUserMakes,
  setIsDeleting,
  setIsMakingCoverLetter,
}: UseUserActionsProps) => {
  const handleDelete = useCallback(
    async (docId: string) => {
      if (!email) {
        console.error("User email is not available.");
        return;
      }

      setIsDeleting((prev) => ({ ...prev, [docId]: true }));

      const success = await sendDeleteWebhook(docId, email);

      if (success) {
        setUserMakes((prevMakes) =>
          prevMakes.filter((make) => make.id !== docId)
        );
        console.log(`Make with docId ${docId} deleted successfully.`);
      } else {
        console.error(`Failed to delete make with docId ${docId}.`);
      }

      setIsDeleting((prev) => ({ ...prev, [docId]: false }));
    },
    [email, setUserMakes, setIsDeleting]
  );

  const handleMakeCoverLetter = useCallback(
    async (docId: string) => {
      if (!email) {
        console.error("User email is not available.");
        return;
      }

      setIsMakingCoverLetter((prev) => ({ ...prev, [docId]: true }));

      const success = await sendMakeCoverLetterWebhook(docId, email);

      if (success) {
        console.log(`Make Cover Letter for docId ${docId} sent successfully.`);
      } else {
        console.error(`Failed to send Make Cover Letter for docId ${docId}.`);
      }

      setIsMakingCoverLetter((prev) => ({ ...prev, [docId]: false }));
    },
    [email, setIsMakingCoverLetter]
  );

  return {
    handleDelete,
    handleMakeCoverLetter,
  };
};
