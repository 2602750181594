// src/modules/Dashboard/components/UserMakes/services/userMakesService.ts

import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
  query,
} from "firebase/firestore";
import { app } from "../../../../../config/firebaseConfig";
import { UserMake, UserMakesData } from "../UserMakes";
import { WEBHOOKS, FIRESTORE_PATHS } from "../../../../../config";

const db = getFirestore(app);

export const fetchUserMakes = async (email: string): Promise<UserMakesData> => {
  const userMakesRef = collection(db, FIRESTORE_PATHS.userMakes(email));
  const userFilesDocRef = doc(db, FIRESTORE_PATHS.userFilesFolderData(email));

  const [querySnapshot, userFilesDoc] = await Promise.all([
    getDocs(query(userMakesRef)),
    getDoc(userFilesDocRef),
  ]);

  const userMakes: UserMake[] = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...(doc.data() as Omit<UserMake, "id">),
  }));

  const userFolderMakes = userFilesDoc.exists()
    ? userFilesDoc.data().userFolderMakes
    : null;

  return { userMakes, userFolderMakes };
};

type WebhookResponse = {
  success: boolean;
  error?: string;
};

const sendWebhook = async (
  url: string | undefined,
  docId: string,
  userEmail: string,
  webhookName: string
): Promise<WebhookResponse> => {
  if (!url) {
    return {
      success: false,
      error: `Webhook URL is not defined. Please set ${webhookName} in your .env file.`,
    };
  }

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        docId,
        email: userEmail,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return { success: true };
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : "Unknown error occurred";
    return {
      success: false,
      error: errorMessage,
    };
  }
};

export const sendDeleteWebhook = async (
  docId: string,
  userEmail: string
): Promise<boolean> => {
  const { success, error } = await sendWebhook(
    WEBHOOKS.MAKE_DELETE,
    docId,
    userEmail,
    "WEBHOOK_MAKE_DELETE"
  );

  if (!success && error) {
    console.error("Error sending delete webhook:", error);
  }

  return success;
};

export const sendMakeCoverLetterWebhook = async (
  docId: string,
  userEmail: string
): Promise<boolean> => {
  const { success, error } = await sendWebhook(
    WEBHOOKS.MAKE_COVER_LETTER,
    docId,
    userEmail,
    "WEBHOOK_MAKE_COVER_LETTER"
  );

  if (!success && error) {
    console.error("Error sending make cover letter webhook:", error);
  }

  return success;
};
