// src/components/primitives/Containers.styled.tsx

import React from "react";
import styled from "styled-components";

export const ModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const LoadingMessageContainer = styled.div`
  text-align: center;
  padding: 20px;
  color: #a4a4a4;
`;
